import React, { useMemo } from 'react'
import { zip } from 'lodash'

import { ComputeInstanceSummary } from '@cloudnatix-types/dashboard'

import { TimePeriod } from 'src/next/utils/time'
import { nanoToMilliSeconds } from 'src/next/utils/unitConversion'

import { ColorMap, StackedSummariesChart } from './StackedSummariesChart'

type TransformedSummary = ComputeInstanceSummary & {
  id: string
  timestamp: number
}

export interface GenericTabTrendViewChartProps {
  data: ComputeInstanceSummary[][]
  duration: TimePeriod
  summaryMetric: keyof ComputeInstanceSummary
  selectedId: string
  setSelectedId: (id: string) => void
  colorMap: ColorMap
}

export const GenericTabTrendViewChart = ({
  data,
  duration,
  summaryMetric,
  selectedId,
  setSelectedId,
  colorMap,
}: GenericTabTrendViewChartProps) => {
  const yAccessorKey = summaryMetric

  const transformedData = useMemo(() => {
    const transformedSummaries = data.map(summaries =>
      summaries.map(
        summary =>
          ({
            ...summary,
            id: summary.groupingValue,
            timestamp: nanoToMilliSeconds(summary.timestampNs!),
          }) as TransformedSummary,
      ),
    )

    // The stacked bar chart expects a data structure different from the API
    // returns (namely, different X-values combined, instead of the same).
    // `zip` accounts for this and regroups the passed arrays:
    // https://lodash.com/docs/#zip
    //
    // After doing so, we filter undefined values, which can arise when f.e.
    // organizations were added later.
    return zip(...transformedSummaries).map(summaries =>
      summaries.filter(summary => summary !== undefined),
    )
  }, [data])

  if (transformedData.length === 0) return null

  return (
    <StackedSummariesChart
      data={transformedData}
      timePeriod={duration}
      selectedId={selectedId}
      setSelectedId={setSelectedId}
      yAccessorKey={yAccessorKey}
      colorMap={colorMap}
      height={680}
      width={1616}
      padding={{ left: 60, top: 20, bottom: 50, right: 0 }}
    />
  )
}
