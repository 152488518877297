import React, { useMemo } from 'react'
import { zip } from 'lodash'
import { PaddingProps, VictoryBarProps } from 'victory'

import { GetTopSummariesResponse, Summary } from '@cloudnatix-types/dashboard'

import { kubernetesWorkloadSummaryMetricMap } from 'src/next/constants/summary'
import { GraphTooltipContainerProps } from 'src/next/components/Graphs/GraphTooltipContainer/GraphTooltipContainer'
import { TopSummariesChartSettingMetric } from 'src/next/components/KubernetesWorkloadCharts/types'
import {
  ColorMap,
  StackedSummariesChart,
} from 'src/next/components/dashboard/tabs/charts/StackedSummariesChart'
import { TimePeriod } from 'src/next/utils/time'
import { nanoToMilliSeconds } from 'src/next/utils/unitConversion'

type TransformedSummary = Summary & {
  id: string
  timestamp: number
}

interface KubernetesTopSummariesChartProps {
  data: GetTopSummariesResponse
  timePeriod: TimePeriod
  summaryMetric: TopSummariesChartSettingMetric
  selectedId?: string
  setSelectedId?: (id: string) => void
  colorMap: ColorMap
  containerProps?: GraphTooltipContainerProps
  width: number
  height: number
  padding?: PaddingProps
}

export const KubernetesTopSummariesChart = ({
  data,
  timePeriod,
  summaryMetric,
  selectedId,
  setSelectedId,
  colorMap,
  containerProps,
  width,
  height,
  padding,
}: KubernetesTopSummariesChartProps) => {
  const yAccessorKey = kubernetesWorkloadSummaryMetricMap[summaryMetric]

  const transformedData = useMemo(() => {
    const topSummariesMsgBySummaryTime = data.topSummaries!

    const transformedSummaries = Object.values(
      topSummariesMsgBySummaryTime,
    ).map(({ summaries }) =>
      summaries!.map(
        summary =>
          ({
            ...summary,
            id: summary.groupingName,
            timestamp: nanoToMilliSeconds(summary.timestampNs!),
          }) as TransformedSummary,
      ),
    )

    // The stacked bar chart expects a data structure different from the API
    // returns (namely, different X-values combined, instead of the same).
    // `zip` accounts for this and regroups the passed arrays:
    // https://lodash.com/docs/#zip
    //
    // After doing so, we filter undefined values, which can arise when f.e.
    // organizations were added later.
    return zip(...transformedSummaries).map(summaries =>
      summaries.filter(summary => summary !== undefined),
    )
  }, [data])

  return (
    <StackedSummariesChart
      data={transformedData}
      timePeriod={timePeriod}
      selectedId={selectedId}
      setSelectedId={setSelectedId}
      yAccessorKey={yAccessorKey}
      colorMap={colorMap}
      containerProps={containerProps}
      width={width}
      height={height}
      padding={padding}
    />
  )
}
