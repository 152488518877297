import React, { useMemo } from 'react'
import { Logout, User } from '@carbon/react/icons'
import * as Popover from '@radix-ui/react-popover'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useOrgs, useUserSelf } from 'src/api'
import { createAuthClient } from 'src/auth/AuthClient'
import { Box, Flex } from 'src/next/components'
import Typography from 'src/next/components/Typography'
import { Tag } from 'src/next/components/ui/Tag'
import { getUserRoleNames } from 'src/next/constants/userRoleNames'

const StyledInitials = styled(Typography)`
  color: var(--cds-text-primary);
  text-align: center;
  background: var(--cds-layer-01);
  aspect-ratio: 1/1;
  border-radius: 50%;
  min-height: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  [data-state='open'] & {
    background: var(--cds-background);
  }
`

const StyledPopoverTrigger = styled(Popover.Trigger)`
  &[data-state='open'] {
    background: var(--cds-layer-01);
  }

  &&:hover {
    color: var(--cds-text-primary);
  }

  && {
    padding: var(--cds-spacing-02);
  }
`

const StyledHeading = styled(Typography)`
  color: var(--cds-text-secondary);
  margin-bottom: var(--cds-spacing-01);
`

const StyledLogout = styled.button`
  appearance: none;
  background: none;
  border: none;
  color: var(--cds-text-secondary);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;

  &:hover {
    color: var(--cds-text-primary);
  }
`

const Section = ({
  heading,
  children,
}: {
  heading: string
  children: React.ReactNode
}) => (
  <Box
    borderBottom="1px solid"
    borderBottomColor="layer-accent-01"
    pb="3"
    mb="4"
  >
    <StyledHeading variant="label-01">{heading}</StyledHeading>
    {children}
  </Box>
)

export const HeaderUser = () => {
  const { t } = useTranslation()
  const { data: user } = useUserSelf()
  const { data } = useOrgs()
  const userRoleNames = useMemo(() => getUserRoleNames(t), [t])
  const userRoles =
    user?.roleBindings?.map(({ role, orgUuid }) => ({
      role: userRoleNames[role || 'USER_ROLE_VIEWER'],
      orgUuid,
      orgName: data?.orgs?.find(org => org.uuid === orgUuid)?.name,
    })) || []

  const signOut = () => createAuthClient().signOut()
  const initials = `${user?.user?.firstName?.[0]}${user?.user?.lastName?.[0]}`

  return (
    <Popover.Root>
      <StyledPopoverTrigger
        className="cds--header__action cds--btn cds--btn--primary"
        data-testid="user-profile-dropdown-trigger"
      >
        <Flex justifyContent="center">
          {initials ? (
            <StyledInitials variant="productive-heading-02">
              {initials}
            </StyledInitials>
          ) : (
            <User size={24} />
          )}
        </Flex>
      </StyledPopoverTrigger>
      <Popover.Content data-testid="user-profile-dropdown-content">
        <Box
          bg="layer-01"
          p="5"
          style={{ boxShadow: '0 2px 12px var(--cds-shadow)' }}
          minWidth="250px"
          maxWidth="300px"
        >
          <Section heading={t('UserProfile.Account')}>
            <strong>
              {user?.user?.firstName} {user?.user?.lastName}
              <br />
              {user?.user?.loginId}
            </strong>
          </Section>
          <Section heading={t('UserProfile.Tenant')}>
            <strong>{user?.tenant ? user?.tenant.name : ''}</strong>
          </Section>
          <Section
            heading={t('UserProfile.Role', { count: userRoles?.length })}
          >
            <Box ml="-4px" mt="2">
              {userRoles.map(role => (
                <Tag key={role.orgUuid}>
                  {role.role}: {role.orgName}
                </Tag>
              ))}
            </Box>
          </Section>
          <StyledLogout onClick={signOut}>
            {t('UserProfile.Logout')}
            <Logout size={20} />
          </StyledLogout>
        </Box>
      </Popover.Content>
    </Popover.Root>
  )
}
