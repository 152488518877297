import { toRelativeUrl } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import React, { Suspense } from 'react'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter, useNavigate } from 'react-router-dom'

import { queryClient } from 'src/api/queryClient'
import { AppRoutes } from 'src/App/AppRoutes'
import { createAuthClient } from 'src/auth/AuthClient'
import Loading from 'src/next/components/Loading'
import { GlobalThemeProvider, ToasterContextProvider } from 'src/next/contexts'
import { cookie } from 'src/next/utils/cookies'

import { GlobalErrorBoundary } from './GlobalErrorBoundary'

// SecurityWrapper builds the function to pass to the `restoreOriginalUri` prop of the `Security` component.
// This needs to be done inside the `BrowserRouter` to have access to the `useNavigate` hook.
const SecurityWrapper = () => {
  const navigate = useNavigate()
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    const url = toRelativeUrl(originalUri || '/', window.location.origin)
    navigate(url, { replace: true })
  }

  return (
    <Security
      oktaAuth={createAuthClient()}
      restoreOriginalUri={restoreOriginalUri}
    >
      <QueryClientProvider client={queryClient}>
        <AppRoutes />
        {import.meta.env.VITE_REACT_QUERY_DEVTOOLS === 'true' && (
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        )}
      </QueryClientProvider>
    </Security>
  )
}

export const App = () => {
  return (
    <GlobalErrorBoundary>
      <Suspense fallback={<Loading withOverlay={false} centered />}>
        <ToasterContextProvider>
          <GlobalThemeProvider theme={cookie.get('theme') || 'dark'}>
            <BrowserRouter>
              <SecurityWrapper />
            </BrowserRouter>
          </GlobalThemeProvider>
        </ToasterContextProvider>
      </Suspense>
    </GlobalErrorBoundary>
  )
}
