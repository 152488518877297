/* eslint-disable react/display-name */
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { VictoryTooltipProps } from 'victory'

import { GraphConfig, StreamGraphProps } from 'src/next/components/Graphs'

export const TooltipWrapperStyles = css`
  background: var(--cds-toggle-off);
  border: 0.5px solid var(--cds-border-strong-01);
  color: var(--cds-text-inverse);
  display: flex;
  flex-direction: column;
  font-family: var(--cn-font-family--condensed);
  font-size: var(--cds-helper-text-01-font-size);
  min-width: 180px;
  pointer-events: none;

  // Hide (border) when tooltip is empty
  &:empty {
    display: none;
  }
`

const TooltipWrapper = styled.div`
  ${TooltipWrapperStyles}
`

export const TooltipRow = styled.div`
  align-content: space-between;
  background: var(--cds-layer-selected-inverse);
  border-left-color: transparent;
  border-left-style: solid;
  border-left-width: 8px;
  display: flex;
  flex-direction: row;
  padding: 1px 4px;
  width: 100%;
  margin-top: 1px;
`

export const TooltipDate = styled.div`
  background: var(--cds-layer-selected-inverse);
  padding: 2px 4px 2px 12px;
  font-weight: bold;
  white-space: pre-line;
`

export const TooltipLabel = styled.div`
  flex: 1;
  margin-right: 1em;
`

interface GraphTooltipRowProps {
  color: string
  label: string
  value: ReactNode
}

export const GraphTooltipRow = ({
  color: borderColor,
  label,
  value,
  ...rest
}: GraphTooltipRowProps) => {
  return (
    <TooltipRow style={{ borderColor }} {...rest}>
      <TooltipLabel>{label}</TooltipLabel>
      {value}
    </TooltipRow>
  )
}

// only a partial interface of the actual object being passed (which
// unfortunately is `any[]` in Victory)
interface ActivePoint {
  x: number
  y: number
  [key: string]: any
}

interface GraphTooltipProps extends Omit<VictoryTooltipProps, 'activePoints'> {
  heading?: ReactNode | ((activePoint?: ActivePoint) => any)
  // NOT required when used within a GraphTooltipContainer
  rows?: GraphTooltipRowProps[]
  // required when used within a GraphTooltipContainer
  graphConfig?: (Omit<GraphConfig, 'data'> | Omit<StreamGraphProps, 'data'>)[]
  // reverse order of rows, especially useful for stacked charts where tooltip
  // values (activePoints) always seems to be reversed
  reverseOrder?: boolean
  // used to lookup what value to show in the tooltip
  valueMutationMap?: Map<string | undefined, (unknown, any) => string>
  tooltipLabelMap?: Map<
    string | undefined,
    string | ((value: unknown) => string | undefined)
  >

  setFloating?: (element: HTMLDivElement | null) => void
  floatingStyles?: React.CSSProperties
  getFloatingProps?: () => React.HTMLAttributes<HTMLDivElement>

  // implicitly passed to component, when used within GraphTooltipContainer
  activePoints?: ActivePoint[]
  x?: number
  y?: number
}

const GraphTooltip = React.forwardRef(
  (
    {
      heading: headingProp,
      rows: rowsProp,
      graphConfig,
      reverseOrder,
      valueMutationMap,
      tooltipLabelMap,

      setFloating,
      floatingStyles,
      getFloatingProps,

      activePoints: activePointsProp,
      x,
      y,
    }: GraphTooltipProps,
    // Prevents the warning 'Function components cannot be given refs'. This ref
    // is implicitly passed when used as a `portalComponent`-prop, but not used
    // in this component.
    //
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _forwardedRef,
  ) => {
    const { t } = useTranslation()

    const activePoints = reverseOrder
      ? [...(activePointsProp || [])].reverse()
      : activePointsProp

    const heading =
      typeof headingProp === 'function'
        ? // if heading is a function, call the function with the active point
          headingProp(activePoints?.[0])
        : // else just use the passed heading prop
          headingProp

    const utilPoint = activePoints?.find(
      ({ childName }) => childName === 'max-usage' || childName === 'avg-usage',
    )
    const source = utilPoint?.source

    function createRows(
      { childName, y, x, style, ...rest }: ActivePoint,
      index: number,
    ) {
      // If the component calling this function hasn't upgraded to tooltipLabelMap then fallback to graphConfig implementation
      if (tooltipLabelMap === undefined) {
        return createRowsByGraphConfig(
          { childName, y, x, style, ...rest },
          index,
        )
      }

      const tooltipLabelFn = tooltipLabelMap!.get(childName)
      if (typeof tooltipLabelFn === 'undefined') return null

      const tooltipLabel =
        typeof tooltipLabelFn === 'function'
          ? tooltipLabelFn({ childName, x, y, ...rest })
          : tooltipLabelFn

      // Verify that the valueMutationMap is not undefined and either call the function or return the value
      const tooltipValue =
        valueMutationMap !== undefined
          ? valueMutationMap!.get(childName) !== undefined
            ? valueMutationMap!.get(childName)!(y, rest)
            : y
          : y

      return (
        <GraphTooltipRow
          key={`${tooltipLabel}-${index}`}
          color={style?.data?.fill || style?.data?.stroke}
          label={tooltipLabel!}
          value={tooltipValue}
        />
      )
    }

    function createRowsByGraphConfig(
      { childName, y, x, style, ...rest }: ActivePoint,
      index: number,
    ) {
      const graphData = graphConfig?.find(item => item.id === childName)

      const { tooltipLabel: tooltipLabelArg, tooltipValueTransformFn } =
        graphData || {}

      if (typeof tooltipLabelArg === 'undefined') return null

      // todo: see if we can prevent function creation on every mousemove
      const tooltipLabel =
        typeof tooltipLabelArg === 'function'
          ? tooltipLabelArg({ childName, x, y, ...rest })
          : tooltipLabelArg

      if (typeof tooltipLabel === 'undefined') return null

      // todo: see if we can prevent function creation on every mousemove
      const tooltipValue =
        typeof tooltipValueTransformFn === 'function'
          ? tooltipValueTransformFn(y, rest)
          : y

      return (
        <GraphTooltipRow
          key={`${tooltipLabel}-${index}`}
          color={style?.data?.fill || style?.data?.stroke}
          label={tooltipLabel}
          value={tooltipValue}
        />
      )
    }

    const rows =
      activePoints?.length && !rowsProp
        ? [
            ...(activePoints?.map(createRows).filter(Boolean) || []),
            ...(source
              ? [
                  <GraphTooltipRow
                    key={'source'}
                    color="var(--carbonPalette14)"
                    label={t('InstanceRecommendation.Legend.Source')}
                    value={source}
                  />,
                ]
              : []),
          ]
        : rowsProp?.length
          ? rowsProp.map(({ label, ...rowProps }) => {
              return <GraphTooltipRow key={label} label={label} {...rowProps} />
            })
          : null

    if (!rows?.length) {
      return null
    }

    return (
      <TooltipWrapper
        ref={setFloating}
        style={{
          ...(floatingStyles ?? {
            position: 'absolute',
            top: y + 10,
            left: x + 10,
          }),
          width: 'max-content',
        }}
        data-testid="graph-tooltip"
        {...(getFloatingProps ? getFloatingProps() : {})}
      >
        {heading ? <TooltipDate>{heading}</TooltipDate> : null}
        {rows}
      </TooltipWrapper>
    )
  },
)

export default GraphTooltip
