import { OktaAuth } from '@okta/okta-auth-js'

export const createAuthClient = () => {
  return new OktaAuth({
    issuer: import.meta.env.VITE_OKTA_ISSUER,
    clientId: import.meta.env.VITE_CLIENT_ID,
    redirectUri: `${window.location.origin}/implicit/callback`,
    pkce: true,
    tokenManager: {
      autoRenew: true,
      autoRemove: true,
      secure: true,
      storage: 'cookie',
      expireEarlySeconds: 60 * 40,
    },
    postLogoutRedirectUri: window.location.origin,
  })
}

export const getAccessToken = () => {
  const authClient = createAuthClient()

  const token = authClient.tokenManager.getTokensSync().accessToken

  if (!token || authClient.tokenManager.hasExpired(token)) {
    authClient.signOut()
  }

  return token
}
