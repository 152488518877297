import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { max, mean, min } from 'lodash'
import { i18n } from 'i18next'
import styled from 'styled-components'
import {
  PaddingProps,
  VictoryAxis,
  VictoryAxisProps,
  VictoryBar,
  VictoryBarProps,
  VictoryChart,
  VictoryStack,
} from 'victory'

import BoundingSize from 'src/next/components/BoundingSize'
import { BarGraphProps, GraphDataPoint } from 'src/next/components/Graphs'
import GraphTooltip, {
  useGraphTooltipFloating,
} from 'src/next/components/Graphs/GraphTooltip'
import GraphTooltipContainer from 'src/next/components/Graphs/GraphTooltipContainer'
import { GraphTooltipContainerProps } from 'src/next/components/Graphs/GraphTooltipContainer/GraphTooltipContainer'
import theme from 'src/next/themes/victory-carbon-theme'
import { DomainPropType } from 'src/next/types/victory'
import { middleEllipsis } from 'src/next/utils/middle-ellipsis'
import { createFormatFn, getFormatType } from 'src/next/utils/graph.utils'

dayjs.extend(utc)

// To make the graph responsive we need this "parent container" that has a fixed
// height. Otherwise, the height of the graph will continue to grow
const FlexibleContainer = styled.div<{ height: number }>`
  position: relative;
  width: 100%;
  height: ${({ height }) => height}px;
`

const getXTickDivision = (
  width: number,
  totalPointsX: number,
  xAxisLabelSpace: number,
) => {
  let division = 1

  for (let i = 1; i < 10; i++) {
    const remainder = width / (totalPointsX / i) - xAxisLabelSpace

    if (remainder > 0) {
      division = i
      break
    }
  }

  return division
}

// to calculate the lines between bars we take the difference of 2 datapoints on
// the x-axis, divide by two and add it to each value.
const getLinesBetweenBars = (data: number[]) => {
  return data?.reduce<number[]>((acc, item, index): number[] => {
    const nextValueInArray = data[index + 1]
    if (!nextValueInArray) return acc

    return [...acc, item + (nextValueInArray - item) / 2]
  }, [])
}

// loops over and returns the average difference between datapoints. This is
// used to determine the extra domain space on the left and right side of the
// bars in the graph.
//
// Note: We assume the difference between the datapoints to be the same. If
// not, this will lead to domain space on the left and right to be off.
const getMeanOfDataPoints = (data: number[]) => {
  if (data.length === 1) return 1 // when just 1 bar

  const values = data?.reduce<number[]>((acc, item, index): number[] => {
    const nextValueInArray = data[index + 1]
    if (!nextValueInArray) return acc

    return [...acc, nextValueInArray - item]
  }, [])

  return mean(values)
}

const getHeading = (selectedId?: string | null, activePoint?: any) => {
  if (selectedId) {
    return middleEllipsis(selectedId, 20, 20)
  }

  const date = dayjs.utc(activePoint?._x)
  return date.isValid() ? date.format('LL') : ''
}

const defaultXTickFormat = (x: number) => dayjs.utc(x).format('MM/DD')

const stackedTooltipValues = (
  config: BarGraphProps[],
  selectedId: string,
  metric: string,
  i18nValue: i18n,
) => {
  return config
    .flatMap(config => config.data as GraphDataPoint[])
    .filter(dataPoint => selectedId === (dataPoint.id as string))
    .sort((a, b) => (a.timestamp as number) - (b.timestamp as number))
    .map((dataPoint: GraphDataPoint) => ({
      value: createFormatFn(getFormatType(metric), {
        cpu: { shortDisplayValue: false },
        memory: { maximumFractionDigits: 2 },
        currency: { minimumFractionDigits: 0 },
      })(dataPoint[metric] as number),
      label: dayjs(dataPoint.timestamp as number)
        .locale(i18nValue.resolvedLanguage)
        .format('ll'),
      // @ts-ignore
      color: dataPoint.style.data.fill,
    }))
}

export interface StackedBarChartProps {
  graphConfigFiltered?: BarGraphProps[] | null
  graphConfig: BarGraphProps[]
  // The accessor key that is used for the x-axis
  xAccessorKey?: string
  yAccessorKey?: string
  yDomain: DomainPropType['y']
  xTickFormat?: VictoryAxisProps['tickFormat']
  yTickFormat?: VictoryAxisProps['tickFormat']
  xAxisLabelSpace?: number
  tooltipHeading?: (
    selectedId?: string | null,
    activePoint?: GraphDataPoint,
  ) => ReactNode
  containerProps?: GraphTooltipContainerProps
  barProps?: VictoryBarProps
  selectedId?: string | null
  width: number
  height: number
  padding?: PaddingProps | undefined
  children?: React.ReactNode
}

const StackedBarChart = ({
  graphConfigFiltered,
  graphConfig,
  xAccessorKey = 'timestamp',
  yAccessorKey = 'count',
  yDomain,
  xTickFormat = defaultXTickFormat,
  yTickFormat,
  xAxisLabelSpace = 38,
  tooltipHeading = getHeading,
  containerProps,
  barProps,
  selectedId,
  width,
  height,
  padding,
  children,
}: StackedBarChartProps) => {
  const { i18n: i18nValue } = useTranslation()

  // The logic below is for:
  // 1. adding lines between the bars
  // 2. calculating the domain so that the bar chart is positioned in the
  //    center, and has even space between the bars and on the left and right
  //    side of the graph

  // the data points for showing the lines between bars. Min and max are used
  // for determining the x-domain.
  const xValues = graphConfig[0]?.data.map(item => {
    return Number(item[xAccessorKey])
  })

  const linesBetweenBars = getLinesBetweenBars(xValues)
  const avgDistanceBetweenPoints = getMeanOfDataPoints(xValues)

  const domain: DomainPropType = {
    x: [
      (min(xValues) || 0) - avgDistanceBetweenPoints / 2,
      (max(xValues) || 100) + avgDistanceBetweenPoints / 2,
    ],
    y: yDomain,
  }

  const config = graphConfigFiltered || graphConfig

  const defaultPadding = {
    left: 60,
    top: 5,
    bottom: 50,
    right: 50,
  }

  // When there is a highlighted area, we provide custom tooltip values
  const tooltipRowValues = selectedId
    ? stackedTooltipValues(config, selectedId, yAccessorKey, i18nValue)
    : undefined

  // Heading was shown even when the highlighted area was disabled in the graph
  // legend. Would be nicer to remove the highlighted area when it's no longer
  // in the graph legend.
  const showTooltipHeading =
    tooltipRowValues === undefined || tooltipRowValues.length > 0

  const { reference, ...graphTooltipProps } = useGraphTooltipFloating()

  return (
    <BoundingSize
      defaultHeight={height}
      defaultWidth={width}
      render={({ width, height }) => {
        // only filter when x-axis label space is set
        const xTickValues = xAxisLabelSpace
          ? xValues.filter(
              (_, i) =>
                i %
                  getXTickDivision(
                    width - (defaultPadding.left + defaultPadding.right),
                    xValues.length,
                    xAxisLabelSpace || 0,
                  ) ===
                0,
            )
          : xValues

        return (
          <FlexibleContainer height={height}>
            {reference}
            <VictoryChart
              theme={theme}
              width={width}
              height={height}
              containerComponent={GraphTooltipContainer(
                <GraphTooltip
                  graphConfig={config}
                  heading={data =>
                    showTooltipHeading
                      ? tooltipHeading(selectedId || null, data)
                      : null
                  }
                  reverseOrder
                  rows={tooltipRowValues}
                  {...graphTooltipProps}
                />,
                containerProps,
              )}
              domain={domain}
              padding={padding || defaultPadding}
            >
              {/**
               * Lines between bars
               * Do not remove `> 0`
               * otherwise runtime error occurs when there's just 1 bar
               */}
              {linesBetweenBars?.length > 0 && (
                <VictoryAxis
                  tickValues={linesBetweenBars}
                  tickFormat={linesBetweenBars.map(() => '')}
                />
              )}

              {/* bar labels */}
              <VictoryAxis
                tickValues={xTickValues}
                tickFormat={xTickFormat}
                style={{ grid: { stroke: 'transparent' } }}
              />

              {/* vertical axis  */}
              <VictoryAxis
                crossAxis={false}
                dependentAxis
                tickFormat={yTickFormat}
              />

              <VictoryStack>
                {config.map(({ data, id, enabled = true }) =>
                  enabled ? (
                    <VictoryBar
                      key={id}
                      name={id}
                      data={data}
                      style={{
                        data: {
                          fill: (d: any) => d?.datum?.style?.data?.fill,
                          opacity: (d: any) => d?.datum?.style?.data?.opacity,
                        },
                      }}
                      x={xAccessorKey}
                      y={yAccessorKey}
                      barWidth={width / (xValues.length * 2)}
                      {...barProps}
                    />
                  ) : null,
                )}
              </VictoryStack>
              {children}
            </VictoryChart>
          </FlexibleContainer>
        )
      }}
    />
  )
}

export default StackedBarChart
