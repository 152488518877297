import { useCallback } from 'react'
import dayjs from 'dayjs'
import { milliToNanoSeconds } from 'src/next/utils/unitConversion'
import { TimeRange } from './TimerangeTypes'

export const useTimeRange = (timeRange: TimeRange) => {
  const lastHour = dayjs().startOf('hour')
  const endTime = lastHour.valueOf()
  const getStartTime = useCallback(
    (value: number, unit: dayjs.ManipulateType) =>
      lastHour.subtract(value, unit).valueOf(),
    [lastHour],
  )

  const startTime =
    timeRange === 'hour'
      ? getStartTime(1, 'hour')
      : timeRange === '12_hours'
        ? getStartTime(12, 'hours')
        : timeRange === '24_hours'
          ? getStartTime(24, 'hours')
          : timeRange === 'week'
            ? getStartTime(1, 'week')
            : timeRange === 'month'
              ? getStartTime(1, 'month')
              : getStartTime(6, 'months')

  return {
    startTime,
    endTime,
    startTimeNs: `${milliToNanoSeconds(startTime)}`,
    endTimeNs: `${milliToNanoSeconds(endTime)}`,
  }
}
