import React, { useState } from 'react'
import {
  PortOutput,
  AiLaunch, //TODO not the correct icon, but the buckets seem different
  KubernetesPod,
  CircleSolid,
  CaretUp,
  CaretDown,
} from '@carbon/react/icons'
import * as Popover from '@radix-ui/react-popover'
import styled from 'styled-components'

import { Box } from 'src/next/components'
import Typography from 'src/next/components/Typography'

export const APP_AI = 'ai'
export const APP_CLUSTERS = 'clusters'

const StyledPopoverTrigger = styled(Popover.Trigger)<{ triggerOpen: boolean }>`
  background: ${({ triggerOpen }) =>
    triggerOpen ? 'var(--cds-layer-01)' : 'transparent'};
  color: var(--cds-text-primary);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 0;
  padding-left: 8px;

  &&:focus {
    outline: none;
  }
  &&:hover {
    background: var(--cds-layer-01);
  }
`

const StyledTriggerText = styled.div`
  padding: 15px 16px 15px 8px;
  display: flex;
  flexdirection: row;
`

const PopoverContentWrapper = styled(Box)`
  padding: 0px;

  background-color: var(--cds-layer-01);
  min-width: 230px;
  max-width: 300px;
  box-shadow: 0 2px 12px var(--cds-shadow);
`

const StyledAppSwitch = styled.button<{ selected: boolean }>`
  appearance: none;
  background-color: ${({ selected }) =>
    selected ? 'rgba(141, 141, 141, 0.24)' : 'transparent'};
  border-radius: 0;
  border-left: ${({ selected }) => (selected ? '4px solid #0F62FE' : '')};
  color: var(--cds-text-secondary);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;

  &:hover {
    color: var(--cds-text-primary);
    border-radius: 0;
    outline: none;
    cursor: pointer;
    background: var(--cds-layer-02);
  }

  && {
    padding: var(--cds-spacing-05);
  }

  &&:focus {
    outline: none;
  }
`
/* Saniya: For options that have not been implmented yet */
const StyledUnavailable = styled.button`
  background-color: transparent;
  border: solid 1px transparent;
  border-radius: 0;
  color: rgba(141, 141, 141, 0.9);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;

  &:hover {
    border-radius: 0;
    outline: none;
    cursor: pointer;
    background: var(--cds-layer-02);
  }
  && {
    padding: var(--cds-spacing-05);
  }

  &&:focus {
    outline: none;
  }
}
`

interface AppTitleProps {
  Icon: React.ElementType
  iconColor: string
  circleColor: string
  text?: string
}

const AppTitle = ({ Icon, iconColor, circleColor, text }: AppTitleProps) => {
  const circleSize = 28
  const overlayIconSize = 14

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'left',
      }}
    >
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <div>
          <CircleSolid
            style={{
              width: circleSize,
              height: circleSize,
              borderRadius: '50%',
              backgroundColor: circleColor,
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
              fill: circleColor,
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Icon
              style={{
                width: overlayIconSize,
                height: overlayIconSize,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: iconColor,
              }}
            />
          </div>
        </div>
      </div>
      {text && (
        <Typography variant="label-02" style={{ marginLeft: '8px' }}>
          {text}
        </Typography>
      )}
    </div>
  )
}

const ToggleArrow = ({ isOpen }) => {
  return isOpen ? <CaretUp size={16} /> : <CaretDown size={16} />
}

const Section = ({ children }: { children: React.ReactNode }) => (
  <Box borderTop="1px solid" borderTopColor="layer-accent-01">
    {children}
  </Box>
)

interface HeaderAppSwitchProps {
  app: string
}

export const HeaderAppSwitch = ({ app }: HeaderAppSwitchProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(prevState => !prevState)
  }

  const aiBaseAppTitleProps = {
    Icon: AiLaunch,
    iconColor: '#BAE6FF',
    circleColor: '#00539A',
  }

  const clusterBaseAppTitleProps = {
    Icon: KubernetesPod,
    iconColor: '#9EF0F0',
    circleColor: '#005D5D',
  }

  const AI_OPS_TITLE = 'AI Ops'
  const INFRA_OPS_TITLE = 'Infrastructure Ops'

  return (
    <Popover.Root onOpenChange={handleToggle}>
      <StyledPopoverTrigger
        className="cds--btn--primary"
        data-testid="switch-app-dropdown-trigger"
        triggerOpen={isOpen}
      >
        {app === APP_AI ? (
          <>
            <AppTitle {...aiBaseAppTitleProps} />
            <StyledTriggerText>
              <Typography variant="heading-01" style={{ marginRight: '3px' }}>
                <strong>{AI_OPS_TITLE}</strong>
              </Typography>
              <ToggleArrow isOpen={isOpen} />
            </StyledTriggerText>
          </>
        ) : app === APP_CLUSTERS ? (
          <>
            <AppTitle {...clusterBaseAppTitleProps} />
            <StyledTriggerText>
              <Typography variant="heading-01" style={{ marginRight: '3px' }}>
                <strong>{INFRA_OPS_TITLE}</strong>
              </Typography>
              <ToggleArrow isOpen={isOpen} />
            </StyledTriggerText>
          </>
        ) : null}
      </StyledPopoverTrigger>

      <Popover.Portal>
        <Popover.Content data-testid="switch-app-dropdown-content">
          <PopoverContentWrapper>
            <StyledAppSwitch
              className="cds--btn--primary"
              selected={app === APP_AI}
              onClick={() => {
                if (app === APP_AI) {
                  return
                }

                window.location.href = import.meta.env.VITE_CNATIX_AI_URL
              }}
            >
              <AppTitle {...aiBaseAppTitleProps} text={AI_OPS_TITLE} />
            </StyledAppSwitch>

            <StyledAppSwitch
              className="cds--btn--primary"
              selected={app === APP_CLUSTERS}
              onClick={() => {
                if (app === APP_CLUSTERS) {
                  return
                }

                window.location.href = import.meta.env.VITE_CNATIX_K8S_URL
              }}
            >
              <AppTitle {...clusterBaseAppTitleProps} text={INFRA_OPS_TITLE} />
            </StyledAppSwitch>
          </PopoverContentWrapper>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}
