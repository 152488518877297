import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'src/next/components'
import DataTable from 'src/next/components/DataTable'
import { UseOrderByProps } from 'src/next/components/DataTable/useOrderBy'
import { InlineNotification } from 'src/next/components/InlineNotification'
import { DataTableHeader } from 'src/next/types/dataTable'

interface TabDataTableProps {
  id: string
  title?: string
  headers: DataTableHeader[]
  rows: Record<string, any>[]
  orderBy: UseOrderByProps
  overflowMenu?: ReactNode
  isLoading?: boolean
}

export const TabDataTable = ({
  id,
  title,
  headers,
  rows,
  orderBy,
  overflowMenu,
  isLoading = false,
}: TabDataTableProps) => {
  const { t } = useTranslation()

  return (
    <div data-testid={id}>
      <DataTable
        size="md"
        storageKey={id}
        headers={headers}
        isLoading={isLoading}
        rows={rows}
        orderBy={orderBy}
        toolbar={
          <Flex
            justifyContent="space-between"
            alignItems="center"
            width={title ? 1 : undefined}
          >
            {title}
            {overflowMenu}
          </Flex>
        }
      />
      {!isLoading && !rows?.length ? (
        <InlineNotification
          title={`${t('Dashboard.Common.DataTable.NoData')}`}
        />
      ) : null}
    </div>
  )
}
