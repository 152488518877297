import { LoginCallback } from '@okta/okta-react'
import React from 'react'
import { Navigate, Route } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { createAuthClient } from 'src/auth/AuthClient'
import { SentryRoutes } from 'src/next/utils/sentry'

import { AppContainer } from './AppContainer'
import { SignIn } from './SignIn'

export const AppRoutes = () => {
  return (
    <SentryRoutes>
      <Route
        path="/implicit/callback/"
        element={<LoginCallback errorComponent={ErrorRedirect} />}
      />
      <Route path="/app/*" element={<SignIn />}>
        <Route path="*" element={<AppContainer />} />
      </Route>
      <Route path="/logout" element={<Logout />} />
      <Route path="*" element={<Navigate to="/app/dashboard" />} />
    </SentryRoutes>
  )
}

const Logout = () => {
  createAuthClient().signOut()
  return null
}

const ErrorRedirect = ({ error }: { error: Error }) => {
  Sentry.captureException(error)
  return <Navigate to={{ pathname: '/' }} />
}
