import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  TableFilterAccordionItem,
  TableFilterFilterableMultiSelect,
  useTableFilter,
} from 'src/next/components/TableFilter'
import { useNamespacesQuery } from '../hooks/useNamespacesQuery'

export const KubernetesWorkloadsTableNamespaceFilter = () => {
  const { t } = useTranslation()

  const { isOpen, filters } = useTableFilter()

  const { data: namespaces, isLoading: isLoadingNamespaces } =
    useNamespacesQuery(
      { filter: { clusterUuids: filters?.clusterUuids } },
      {
        enabled: isOpen,
        staleTime: 900000,
        select: data => data.namespaces,
      },
    )

  return (
    <TableFilterAccordionItem
      title={t('Workloads.K8sTable.Filters.Namespaces')}
    >
      <TableFilterFilterableMultiSelect
        id="namespaces"
        placeholder={t('Workloads.K8sTable.Filters.NamespacesPlaceHolder')}
        isLoading={isLoadingNamespaces}
        items={namespaces || []}
      />
    </TableFilterAccordionItem>
  )
}
