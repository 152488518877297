import { useOktaAuth } from '@okta/okta-react'
import React from 'react'
import { Outlet } from 'react-router-dom'

import Loading from 'src/next/components/Loading'

export const SignIn = () => {
  const { oktaAuth, authState } = useOktaAuth()

  if (!authState) {
    return <Loading withOverlay={false} centered />
  }

  if (!authState.isAuthenticated) {
    oktaAuth.signInWithRedirect({
      response_type: 'id_token',
      scopes: ['openid', 'email', 'profile', 'offline_access'],
      state: import.meta.env.VITE_LOGIN_STATE,
      nonce: import.meta.env.VITE_LOGIN_NONCE,
      originalUri: `${window.location.href}`,
    })
    return null
  }

  return <Outlet />
}
