import { useTranslation } from 'react-i18next'
import { ManagementResourceType } from '@cloudnatix-types/dashboard'

export interface ResourceTypeProps {
  type?: string
}

export const ResourceType = ({ type }: ResourceTypeProps) => {
  const { t } = useTranslation()

  return (
    <>
      {type === ManagementResourceType.CLUSTER
        ? t('Common.Cluster')
        : type === ManagementResourceType.COMPUTE_INSTANCE
          ? t('Common.ComputeInstance')
          : ''}
    </>
  )
}
