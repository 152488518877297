import { usePagination } from './DataTablePagination'
import { useOrderBy } from './useOrderBy'

// combines usePagination and useOrderBy to simplify usage
export const useTableControls = (key: string, initialPageSize = 10) => {
  const pagination = usePagination(key, initialPageSize)

  return {
    pagination,
    orderBy: useOrderBy({ resetPage: pagination.resetPage }),
  }
}
