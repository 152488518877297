import React from 'react'
import { useMemo } from 'react'
import {
  flip,
  offset,
  shift,
  useClientPoint,
  useFloating,
  useInteractions,
} from '@floating-ui/react'

export const useGraphTooltipFloating = () => {
  // todo: see if we can add a small delay to improve performance when the
  // graph has many points
  const {
    context,
    floatingStyles,
    refs: { setReference, setFloating },
  } = useFloating({
    placement: 'bottom-start',
    strategy: 'absolute',
    middleware: [
      offset({ mainAxis: 10, crossAxis: 10 }),
      flip({ padding: 50 }),
      shift(),
    ],
  })

  const clientPoint = useClientPoint(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([clientPoint])

  return useMemo(
    () => ({
      reference: <div ref={setReference} {...getReferenceProps()}></div>,
      setFloating,
      floatingStyles,
      getFloatingProps,
    }),
    [
      setReference,
      getReferenceProps,
      setFloating,
      floatingStyles,
      getFloatingProps,
    ],
  )
}
