import React, { useMemo } from 'react'
import { Dropdown } from '@carbon/react'
import dayjs from 'dayjs'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { Box } from 'src/next/components/Box'
import { useTimeRangeOverflowMenu } from 'src/next/components/dashboard/timerange'
import { GraphConfig, useLegend } from 'src/next/components/Graphs'
import { defaultPadding, Graph } from 'src/next/components/Graphs/Graph'
import GraphTooltip, {
  useGraphTooltipFloating,
} from 'src/next/components/Graphs/GraphTooltip'
import GraphTooltipContainer from 'src/next/components/Graphs/GraphTooltipContainer'
import Loading from 'src/next/components/Loading'
import { Toolbar } from 'src/next/components/Toolbar'
import { usePersistentDropdown } from 'src/next/hooks/usePersistentDropdown'
import { getFormatType } from 'src/next/utils/graph.utils'
import { getTooltipHeading } from './ClustersSummaryChart'
import { useCompareMetrics } from './useCompareMetrics'

export const ClustersCompareChart = () => {
  const { t } = useTranslation()

  const dropdownItems = useMemo(() => getTopSummariesDropdownItems(t), [t])

  const {
    dropdownProps: { selectedItem: metricToPlot, ...metricDropdownProps },
  } = usePersistentDropdown('clusters-overview-compare-metric', dropdownItems)

  const { TimeRangeMenu, items, selectedItem, startTimeNs, endTimeNs } =
    useTimeRangeOverflowMenu({
      id: 'clusters-overview-chart-time-range',
      show24HoursOption: false,
    })

  const { isLoading, graphConfig } = useCompareMetrics({
    metricToPlot: metricToPlot.id,
    startTimeNs,
    endTimeNs,
  })

  const {
    Legend,
    graphConfig: legendGraphConfig,
    legendProps,
  } = useLegend<GraphConfig>(graphConfig || [])

  const hasData = legendGraphConfig.some(
    graph => graph.data && graph.data.length > 0 && graph.enabled,
  )

  const unit = getFormatType(metricToPlot.id) || 'cores'

  const { reference, ...graphTooltipProps } = useGraphTooltipFloating()

  return (
    <Box position="relative">
      <Toolbar>
        <Toolbar.Group pullRight>
          <Box minWidth={225}>
            <Dropdown
              hideLabel
              label={t('Dashboard.Namespace.Metric')}
              selectedItem={metricToPlot}
              {...metricDropdownProps}
            />
          </Box>

          <TimeRangeMenu label={selectedItem.label} items={items} />
        </Toolbar.Group>
      </Toolbar>

      <Box width="100%" height={350} mt={20}>
        {isLoading ? (
          <Loading
            small
            centered
            withOverlay={false}
            data-testid="loading-clusters-graphs"
          />
        ) : !hasData ? (
          <p>No data found (for selected time range)</p>
        ) : (
          <>
            {reference}
            <Graph
              data={legendGraphConfig.filter(config => config.enabled)}
              unit={unit}
              xAxis={[
                {
                  // custom tick values to correct auto tz behaviour Victory
                  tickValues: legendGraphConfig?.[0]?.data?.map(
                    d => new Date(Number(d.x)),
                  ),
                  tickFormat: (x: any) => dayjs(x).format('MM/DD'),
                },
              ]}
              yAxis={[{}]}
              containerComponent={GraphTooltipContainer(
                <GraphTooltip
                  graphConfig={graphConfig}
                  heading={getTooltipHeading}
                  {...graphTooltipProps}
                />,
              )}
            />
          </>
        )}
      </Box>

      <Box
        width="100%"
        padding={4}
        paddingTop={2}
        paddingLeft={`${defaultPadding.left}px`}
      >
        {!isLoading && hasData ? <Legend {...legendProps} /> : null}
      </Box>
    </Box>
  )
}

const getTopSummariesDropdownItems = (t: TFunction) => [
  { id: 'totalSpend', label: t('Resources.TotalSpend') },
  { id: 'cpu.avgUsage', label: t('Resources.AvgCpuUsage') },
  { id: 'cpu.maxUsage', label: t('Resources.MaxCpuUsage') },
  { id: 'cpu.requested', label: t('Clusters.Comparison.CpuRequested') },
  { id: 'cpu.capacity', label: t('Clusters.Comparison.CpuCapacity') },
  { id: 'memory.avgUsage', label: t('Resources.AvgMemoryUsage') },
  { id: 'memory.maxUsage', label: t('Resources.MaxMemoryUsage') },
  { id: 'memory.requested', label: t('Clusters.Comparison.MemoryRequested') },
  { id: 'memory.capacity', label: t('Clusters.Comparison.MemoryCapacity') },
]
