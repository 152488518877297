import * as Sentry from '@sentry/react'
import axios, { AxiosRequestConfig } from 'axios'

import { createAuthClient, getAccessToken } from 'src/auth/AuthClient'

const createAxios = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
})

export const axiosInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
  const promise = createAxios({ ...config })
    .then(({ data }) => data)
    .catch(error => {
      throw error
    })
  return promise
}

createAxios.interceptors.request.use(
  async config => {
    const accessToken = getAccessToken()
    if (accessToken?.accessToken && config.headers) {
      config.headers.Authorization = `Bearer ${accessToken?.accessToken}`
    }
    return config
  },
  error => {
    return Promise.reject(error.response)
  },
)

createAxios.interceptors.response.use(
  response => response,
  error => {
    Sentry.captureException(error)

    if (error && error?.response?.status === 401) {
      return setTimeout(() => {
        createAuthClient().signOut()
      }, 1000)
    }
    return Promise.reject(error.response)
  },
)

export function fetchData<T>(
  url: string,
  params?: any,
  extraConfig?: AxiosRequestConfig,
) {
  return axiosInstance<T>({
    ...extraConfig,
    url,
    method: 'get',
    params,
    paramsSerializer,
  })
}

export async function fetchAllPages<ElementType, ResponseType>(
  url: string,
  fieldName: string,
  params?: any,
  extraConfig?: AxiosRequestConfig,
): Promise<ElementType[]> {
  const results: ElementType[] = []
  let pageToken = ''
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const response = await fetchData<ResponseType>(
      url,
      { pageToken, ...params },
      extraConfig,
    )
    results.push(...(response[fieldName] as ElementType[]))
    if (!response.nextPageToken) {
      break
    }
    pageToken = response.nextPageToken
  }
  return results
}

export const paramsSerializer = (query: any) => {
  return (
    Object.entries(query)
      .filter(([, value]) => value !== undefined)
      // react hook form fix for filters
      .map(([key, value]) => [key.replace('_', '.'), value])
      .map(([key, value]) =>
        Array.isArray(value)
          ? `${key}=${value.join(`&${key}=`)}`
          : `${key}=${value}`,
      )
      .join('&')
  )
}

export function mutateData<T, K>(url: string, data: K, method: 'put' | 'post') {
  return axiosInstance<T>({ url, method, data })
}

export function deleteData<T>(url: string) {
  return axiosInstance<T>({ url, method: 'delete' })
}

export default createAxios
