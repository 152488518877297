import { useMemo } from 'react'

export const useSortedRows = (
  rows: Record<string, any>[],
  orderBy: string | undefined,
) => {
  const [column, direction] = orderBy?.split(' ') || []

  return useMemo(() => {
    // Copy the rows array to avoid mutating the original.
    const result = [...rows]

    result.sort((a, b) => {
      if (column === '') {
        return a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      }

      const valueA = a[column]
      const valueB = b[column]

      if (valueA === undefined) {
        return 1
      }

      if (valueB === undefined) {
        return -1
      }

      return valueA > valueB ? 1 : valueB > valueA ? -1 : 0
    })

    if (direction === 'desc') {
      result.reverse()
    }

    return result
  }, [rows, column, direction])
}
