/* eslint-disable react/display-name */
import React, { memo } from 'react'
import { HeaderGlobalBar } from '@carbon/react'

import { useUserSelf } from 'src/api'

import { LanguageSwitcher } from './ChangeLanguage'
import { CLUIToggle } from './CLUIToggle'
import { HeaderThemeSwitch } from './HeaderThemeSwitch'
import { HeaderUser } from './HeaderUser'
import { HeaderAppSwitch, APP_CLUSTERS } from './HeaderAppSwitch'

export const HeaderRightMenu = memo(() => {
  const { data: user } = useUserSelf()

  return (
    <HeaderGlobalBar>
      <HeaderAppSwitch app={APP_CLUSTERS} />
      <CLUIToggle />
      <LanguageSwitcher />
      <HeaderThemeSwitch />
      <HeaderUser />
    </HeaderGlobalBar>
  )
})
