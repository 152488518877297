import { Edit, EventSchedule, TrashCan } from '@carbon/react/icons'
import { Button, ButtonProps } from '@carbon/react'

interface DataTableActionButtonProps extends ButtonProps<'button'> {
  actionType?: 'edit' | 'delete' | 'schedule'
}

const iconMap = {
  edit: Edit,
  delete: TrashCan,
  schedule: EventSchedule,
}

export const DataTableActionButton = ({
  actionType,
  ...buttonProps
}: DataTableActionButtonProps) => {
  return (
    <Button
      kind="ghost"
      tooltipPosition="left"
      hasIconOnly
      renderIcon={iconMap[actionType || 'edit']}
      {...buttonProps}
    />
  )
}
