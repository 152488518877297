import { useEffect, useMemo, useState } from 'react'
import { Dropdown, SelectSkeleton } from '@carbon/react'
import { OverflowMenu } from '@carbon/react/lib/components/OverflowMenu'
import { Calendar, Time } from '@carbon/react/icons'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import {
  ComputeInstanceGroup,
  ComputeInstanceGroupingKeyKey,
  ComputeInstanceSummary,
  SummaryInterval,
  SummaryRange,
} from '@cloudnatix-types/dashboard'

import {
  useDashboardInstanceGroups,
  useInstanceSummaries,
  useInstanceSummariesRollupQuery,
} from 'src/api'
import { Box, Flex, LegendItem, Link } from 'src/next/components'
import { useOrderBy } from 'src/next/components/DataTable'
import { InlineNotification } from 'src/next/components/InlineNotification'
import Loading from 'src/next/components/Loading'
import MiddleTruncate from 'src/next/components/MiddleTruncate'
import { useComputeInstanceSummariesMetricsDropdownItems } from 'src/next/components/dashboard/tabs/charts/useFilterOptions'
import { CollapseComponent } from 'src/next/components/ui'
import { computeInstanceSummaryMetricMap } from 'src/next/constants/summary'
import { TimePeriod } from 'src/next/utils/time'
import { usePersistentDropdown } from 'src/next/hooks/usePersistentDropdown'
import {
  createFormatFn,
  getCarbonPaletteCssVariable,
  getFormatType,
} from 'src/next/utils/graph.utils'
import { GraphColor } from 'src/next/types/workloads'

import { useRollupPeriod, useTimeRangeOverflowMenu } from '../timerange'
import { TabDataTable } from './tables'
import { useGroupingHeaders } from './useGroupingHeaders'
import { GenericTabTrendViewChart } from './charts/GenericTabTrendViewChart'
import { useSortedRows } from './tables/useSortedRows'

const LegendWrapper = styled.div`
  display: inline-block;

  li {
    margin: 0;
  }
`

interface LocationState {
  pathname: string
  search: string
}

interface FormattedComputeInstanceSummary extends ComputeInstanceSummary {
  id: string
  name: string
  nameFormatted: JSX.Element
  color: GraphColor
}

const formatComputeInstanceSummaries = (
  groupingKey: string,
  // `GetTopSummariesResponse` and `GetTopComputeInstanceSummariesResponse` differ only slightly.
  //
  // Eventually we might want to combine the endpoints of:
  // - /v1/dashboard/infrastructure/compute/instancesummaries:rollup (for generic tab) &
  // - /v1/dashboard/kubernetes/summaries:rollup (for Namespace)
  // altogether and merge the components.
  summaries: ComputeInstanceSummary[],
  linkState: LocationState,
): FormattedComputeInstanceSummary[] => {
  const formattedSummaries = summaries.map(summary => {
    const groupName = summary.groupingName!

    const nameElement = (
      <MiddleTruncate text={groupName} charsAtStart={30} charsAtEnd={30} />
    )

    const formattedSummary = {
      ...summary,
      id: summary.groupingValue!,
      name: groupName,
      nameFormatted:
        groupingKey === 'INSTANCE_ID' ? (
          <Link
            to={`/app/vm-workload/${encodeURIComponent(
              groupName,
            )}?tabs=recommendations`}
            state={linkState}
          >
            {nameElement}
          </Link>
        ) : (
          nameElement
        ),
    } as Omit<FormattedComputeInstanceSummary, 'color'>

    Object.values(computeInstanceSummaryMetricMap).forEach(metric => {
      formattedSummary[`${metric}Formatted`] = createFormatFn(
        getFormatType(metric),
        {
          cpu: { shortDisplayValue: true },
        },
      )(summary[metric])
    })

    return formattedSummary
  })

  // Sort and assign colors.
  return formattedSummaries
    .sort(({ name: an }, { name: bn }) => (an > bn ? 1 : bn > an ? -1 : 0))
    .map((item, i) => ({
      color: getCarbonPaletteCssVariable(i),
      ...item,
    }))
}

interface IndividualGroupDropdownProps {
  id: string
  groups: ComputeInstanceGroup[]
  setSelectedItem: (id: string) => void
}

const IndividualGroupDropdown = ({
  id,
  groups,
  setSelectedItem,
}: IndividualGroupDropdownProps) => {
  const { t } = useTranslation()

  const items = useMemo(
    () =>
      groups
        .sort((a, b) => {
          if (a.name! < b.name!) return -1
          if (a.name! > b.name!) return 1
          return 0
        })
        .map(({ name, uid }) => ({
          id: uid!,
          label: name!,
        })),
    [groups],
  )

  const {
    dropdownProps: { selectedItem, ...individualGroupDropdownProps },
  } = usePersistentDropdown(id, items)

  useEffect(() => {
    setSelectedItem(selectedItem.id)
  }, [selectedItem, setSelectedItem])

  return (
    <Dropdown
      label={t('Workloads.TopCharts.Rollup.Heading.IndividualGroup')}
      hideLabel
      selectedItem={selectedItem}
      {...individualGroupDropdownProps}
    />
  )
}

export interface GenericTabTrendViewProps {
  id: string
  groupingKeyKey: `${ComputeInstanceGroupingKeyKey}`
  groupingKeyTagKey: string | undefined
  noLimit: boolean
}

export const GenericTabTrendView = ({
  id,
  groupingKeyKey,
  groupingKeyTagKey,
  noLimit,
}: GenericTabTrendViewProps) => {
  const { t } = useTranslation()

  const limitItems = useMemo(
    () => [
      {
        id: 'limit5',
        limitValue: 5,
        label: `${t('Workloads.TopCharts.Rollup.FilterItems.Top')} 5`,
      },
      {
        id: 'limit10',
        limitValue: 10,
        label: `${t('Workloads.TopCharts.Rollup.FilterItems.Top')} 10`,
      },
      ...(noLimit === true
        ? [
            {
              id: 'noLimit',
              limitValue: 0,
              label: t('Workloads.TopCharts.Rollup.FilterItems.All'),
            },
          ]
        : []),
      {
        id: 'individual',
        limitValue: 1,
        label: t('Workloads.TopCharts.Rollup.FilterItems.Individual'),
      },
    ],
    [noLimit, t],
  )

  const {
    dropdownProps: { selectedItem: limit, ...limitDropdownProps },
  } = usePersistentDropdown(`${id}-limit`, limitItems)

  const isOrgGroupingTopNMode =
    groupingKeyKey === ComputeInstanceGroupingKeyKey.ORG &&
    limit.id !== 'individual'

  const orgOptionItems = [
    ...(limit.id !== 'individual'
      ? [
          {
            id: 'OnlyDirectChildren',
            label: t(
              'Workloads.TopCharts.Rollup.FilterItems.OnlyDirectChildren',
            ),
          },
        ]
      : []),
    {
      id: 'AllDescendants',
      label: t('Workloads.TopCharts.Rollup.FilterItems.AllDescendants'),
    },
  ]
  const {
    dropdownProps: { selectedItem: orgOption, ...orgOptionDropdownProps },
  } = usePersistentDropdown(`${id}-org-option`, orgOptionItems)

  const isAccountGroupingTopNMode =
    groupingKeyKey === ComputeInstanceGroupingKeyKey.ACCOUNT &&
    limit.id !== 'individual'

  const descendantOrgOptionItems = [
    {
      id: 'IncludeAllDescendantOrgs',
      label: t(
        'Workloads.TopCharts.Rollup.FilterItems.IncludeAllDescendantOrgs',
      ),
    },
    ...(limit.id !== 'individual'
      ? [
          {
            id: 'OnlyCurrentOrg',
            label: t('Workloads.TopCharts.Rollup.FilterItems.OnlyCurrentOrg'),
          },
        ]
      : []),
  ]
  const {
    dropdownProps: {
      selectedItem: descendantOrgOption,
      ...descendantOrgOptionDropdownProps
    },
  } = usePersistentDropdown(
    `${id}-descendant-org-option`,
    descendantOrgOptionItems,
  )

  // For the combination of the org grouping mode and the top N mode, we provide an option to switch between 2 modes about descendant handling.
  // When the mode is "all descendants", we use the "ORG_EXCLUDING_SUB" grouping key to retrieve aggregated data counting
  // only compute instances directly belonging to each descendant org.
  // On the other hand, when the mode is "only direct children", we use the "ORG" grouping to retrieve aggregated data counting
  // compute instances belonging to all the descendants of each direct children org.
  //
  // For the individual mode, for now, we provide only the "all descendants" option.
  // Showing only the compute instances directly belong to the selected group might be useful for some people,
  // but it's a bit difficult to provide the option without causing confusion.
  const effectiveGroupingKeyKey = useMemo(() => {
    switch (groupingKeyKey) {
      case ComputeInstanceGroupingKeyKey.ORG:
        if (limit.id !== 'individual' && orgOption.id === 'AllDescendants') {
          return ComputeInstanceGroupingKeyKey.ORG_EXCLUDING_SUB
        }

        return groupingKeyKey
      case ComputeInstanceGroupingKeyKey.ACCOUNT:
        if (
          limit.id !== 'individual' &&
          descendantOrgOption.id === 'OnlyCurrentOrg'
        ) {
          return ComputeInstanceGroupingKeyKey.ACCOUNT_EXCLUDING_DESCENDANT_ORGS
        }

        return groupingKeyKey
      case ComputeInstanceGroupingKeyKey.CSP:
      case ComputeInstanceGroupingKeyKey.CLUSTER:
      case ComputeInstanceGroupingKeyKey.TAG:
      case ComputeInstanceGroupingKeyKey.INSTANCE_TYPE:
      case ComputeInstanceGroupingKeyKey.INSTANCE_ID:
        return groupingKeyKey
      case ComputeInstanceGroupingKeyKey.REGION:
      case ComputeInstanceGroupingKeyKey.INSTANCE_NAME:
      default:
        // Throw for unexpected grouping keys. This doesn't necessarily mean that the backend doesn't work for them.
        throw new Error('Unsupported grouping key ${groupingKeyKey}')
    }
  }, [groupingKeyKey, limit.id, orgOption.id, descendantOrgOption.id])
  const groupingKey = {
    key: effectiveGroupingKeyKey as ComputeInstanceGroupingKeyKey,
    tagKey: groupingKeyTagKey,
  }

  const {
    items: timeRangeOverflowMenuItems,
    selectedItem: timeRangeSelectedItem,
    startTimeNs,
    endTimeNs,
  } = useTimeRangeOverflowMenu({
    id: `${id}-time-range`,
    show1YearOption: true,
  })

  const {
    items: durationOverflowMenuItems,
    selectedItem: durationSelectedItem,
  } = useRollupPeriod({
    id: `${id}-duration`,
    timeRange: timeRangeSelectedItem.id,
  })

  const timePeriod = durationSelectedItem.id.toLowerCase() as TimePeriod

  const summaryInterval = durationSelectedItem.id as SummaryInterval

  const {
    isFetching: isInstanceGroupsDataFetching,
    isError: isInstanceGroupsDataError,
    data: instanceGroupsData,
  } = useDashboardInstanceGroups(
    {
      groupingKey,
      filter: {
        startTimeNs,
        endTimeNs,
      },
    },
    {
      enabled: limit.id === 'individual',
    },
  )

  const [selectedIndividualGroup, setSelectedIndividualGroup] = useState<
    string | undefined
  >(undefined)

  const metricItems = useComputeInstanceSummariesMetricsDropdownItems()
  const {
    dropdownProps: { selectedItem: metric, ...metricDropdownProps },
  } = usePersistentDropdown(`${id}-metric`, metricItems)

  const range =
    timeRangeSelectedItem.id === 'week'
      ? SummaryRange.LAST_WEEK
      : timeRangeSelectedItem.id === 'month'
        ? SummaryRange.LAST_MONTH
        : timeRangeSelectedItem.id === '6months'
          ? SummaryRange.LAST_6MONTHS
          : timeRangeSelectedItem.id === '1year'
            ? SummaryRange.LAST_YEAR
            : SummaryRange.SUMMARY_RANGE_UNSPECIFIED

  const {
    data: rollupData,
    isIdle: isRollupIdle,
    isLoading: isRollupLoading,
    isError: isRollupError,
    error: rollupError,
  } = useInstanceSummariesRollupQuery(
    {
      limit: limit.limitValue,
      summaryMetrics: metric.id as any,
      filter: {
        startTimeNs,
        endTimeNs,
        range,
        grouping: {
          groupingKey,
          duration: summaryInterval,
        },
      },
      ...(isOrgGroupingTopNMode &&
        orgOption.id === 'OnlyDirectChildren' && {
          onlyDirectChildren: true,
        }),
    },
    {
      enabled: limit.id !== 'individual',
    },
  )

  const {
    data: summariesData,
    isIdle: isSummariesIdle,
    isLoading: isSummariesLoading,
    isError: isSummariesError,
    error: summariesError,
  } = useInstanceSummaries(
    {
      filter: {
        startTimeNs,
        endTimeNs,
        range,
        grouping: {
          groupingKey,
          groupingValue: selectedIndividualGroup,
          duration: summaryInterval,
        },
      },
    },
    {
      enabled:
        limit.id === 'individual' && selectedIndividualGroup !== undefined,
    },
  )

  const { topSummaries, groupSummaries, isIdle, isLoading, isError, error } =
    (() => {
      if (limit.id === 'individual') {
        return {
          topSummaries: summariesData
            ? summariesData.summaries!.map(summary => [summary])
            : [],
          groupSummaries: summariesData
            ? [summariesData.aggregatedSummary!.aggregatedSummary!]
            : [],
          isIdle: isSummariesIdle,
          isLoading: isSummariesLoading,
          isError: isSummariesError,
          error: summariesError,
        }
      }

      return {
        topSummaries: rollupData
          ? Object.values(rollupData.topSummaries!).map(
              ({ summaries }) => summaries!,
            )
          : [],
        groupSummaries: rollupData ? rollupData.groupSummaries! : [],
        isIdle: isRollupIdle,
        isLoading: isRollupLoading,
        isError: isRollupError,
        error: rollupError,
      }
    })()

  const headers = useGroupingHeaders({
    metrics: computeInstanceSummaryMetricMap,
    overrideNameColumnHeader: groupingKeyTagKey,
  })

  const location = useLocation()
  const linkState = useMemo(
    () => ({
      pathname: location.pathname,
      search: location.search,
    }),
    [location],
  )

  const [selectedId, setSelectedId] = useState<string>('')

  // reset selection when different filter options are chosen
  useEffect(() => {
    setSelectedId('')
  }, [groupSummaries])

  // `formattedGroupSummaries` is sorted by `name`.
  const formattedGroupSummaries = useMemo(() => {
    return formatComputeInstanceSummaries(
      groupingKeyKey,
      groupSummaries,
      linkState,
    ).map(summary => {
      const isSelected = selectedId === summary.id

      return {
        ...summary,
        legendSelection: (
          <LegendWrapper>
            <LegendItem
              id={`datatable-legend-${summary.id}`}
              color={summary.color}
              readOnly
              checked={selectedId ? isSelected : true}
              isSelected={!!selectedId}
            />
          </LegendWrapper>
        ),
        onClick: (row: FormattedComputeInstanceSummary) => {
          setSelectedId(selectedId === row.id ? '' : row.id)
        },
        isSelected,
      }
    })
  }, [
    limit.id,
    rollupData,
    summariesData,
    groupingKeyKey,
    linkState,
    selectedId,
  ])

  // Assignment happens in the order of the `name` field as `formattedGroupSummaries` is sorted so.
  const colorMap = useMemo(() => {
    return formattedGroupSummaries.map(({ id, groupingName, color }) => ({
      id,
      name: groupingName!,
      color,
    }))
  }, [topSummaries])

  const apiMetricID = computeInstanceSummaryMetricMap[metric.id]

  const orderBy = useOrderBy({ defaultValue: `${apiMetricID} desc` })
  const orderedRows = useSortedRows(formattedGroupSummaries, orderBy.value)

  const sortedTopSummaries = useMemo(() => {
    const orderMap = new Map<string, number>()
    orderedRows.forEach((row, i) => orderMap.set(row.id, i))

    return topSummaries.map(summaries =>
      summaries.sort((a, b) => {
        const aOrder = orderMap.get(a.groupingValue!)
        const bOrder = orderMap.get(b.groupingValue!)

        if (aOrder !== undefined && bOrder !== undefined) {
          return bOrder - aOrder
        }
        if (aOrder === undefined) return 1
        if (bOrder === undefined) return -1
        return 0
      }),
    )
  }, [topSummaries])

  return (
    <>
      <Box mt={5} mb={3}>
        <Flex>
          <Box mr="auto">
            <Flex gap="var(--cds-spacing-04)">
              <Dropdown
                label={t('Workloads.TopCharts.Rollup.Heading.Limit')}
                hideLabel
                selectedItem={limit}
                {...limitDropdownProps}
              />
              {isOrgGroupingTopNMode ? (
                <Dropdown
                  label={t('Workloads.TopCharts.Rollup.Heading.OrgOption')}
                  hideLabel
                  selectedItem={orgOption}
                  {...orgOptionDropdownProps}
                />
              ) : null}
              {isAccountGroupingTopNMode ? (
                <Dropdown
                  label={t(
                    'Workloads.TopCharts.Rollup.Heading.DescendantOrgOption',
                  )}
                  hideLabel
                  selectedItem={descendantOrgOption}
                  {...descendantOrgOptionDropdownProps}
                />
              ) : null}
              {limit.id === 'individual' ? (
                <Box minWidth="200px">
                  {isInstanceGroupsDataFetching ? (
                    <SelectSkeleton hideLabel />
                  ) : isInstanceGroupsDataError ||
                    instanceGroupsData === undefined ? (
                    <InlineNotification
                      title={t(
                        'Workloads.TopCharts.Rollup.Heading.IndividualGroup.LoadingError',
                      )}
                      kind="error"
                    />
                  ) : instanceGroupsData.length === 0 ? (
                    <InlineNotification
                      title={t(
                        'Workloads.TopCharts.Rollup.Heading.IndividualGroup.NoData',
                      )}
                      kind="info"
                    />
                  ) : (
                    <IndividualGroupDropdown
                      id={`${id}-individual-group`}
                      groups={instanceGroupsData}
                      setSelectedItem={setSelectedIndividualGroup}
                    />
                  )}
                </Box>
              ) : null}
              <Dropdown
                label={t('Workloads.TopCharts.Rollup.Heading.Metric')}
                hideLabel
                selectedItem={metric}
                {...metricDropdownProps}
              />
            </Flex>
          </Box>
          <OverflowMenu
            data-testid={`${id}-time-range`}
            flipped
            iconDescription={t('Common.DateFilter.SelectTimeRange')}
            aria-label={t('Common.DateFilter.SelectTimeRange')}
            renderIcon={Calendar}
          >
            {timeRangeOverflowMenuItems}
          </OverflowMenu>
          <OverflowMenu
            data-testid={`${id}-duration`}
            flipped
            iconDescription={t('Common.DateFilter.SelectRollupPeriod')}
            aria-label={t('Common.DateFilter.SelectRollupPeriod')}
            renderIcon={Time}
          >
            {durationOverflowMenuItems}
          </OverflowMenu>
        </Flex>
      </Box>
      {isError ? (
        <InlineNotification
          title={`${t('Workloads.TopCharts.Rollup.LoadingError')} ${
            error?.data?.message
          }`}
          kind="error"
        />
      ) : (
        <>
          <CollapseComponent>
            <Box minHeight={300}>
              {isIdle || isLoading ? (
                <Box height="450px" position="relative">
                  <Loading centered withOverlay={false} />
                </Box>
              ) : (
                <GenericTabTrendViewChart
                  data={sortedTopSummaries}
                  duration={timePeriod}
                  summaryMetric={apiMetricID}
                  selectedId={selectedId}
                  setSelectedId={setSelectedId}
                  colorMap={colorMap}
                />
              )}
            </Box>
          </CollapseComponent>
          <Box mt={5}>
            <TabDataTable
              id={`${id}-table`}
              title={t('Common.Rollup', {
                timePeriod: timeRangeSelectedItem.label,
              })}
              headers={headers}
              rows={orderedRows}
              orderBy={orderBy}
              isLoading={isIdle || isLoading}
            />
          </Box>
        </>
      )}
    </>
  )
}
