import { useEffect, useState } from 'react'

export const useOrderBy = ({
  resetPage,
  defaultValue,
}: {
  resetPage?: () => void
  defaultValue?: string
}) => {
  const [value, setValue] = useState<string | undefined>(defaultValue)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const onChange = (value: string | undefined) => {
    resetPage?.()
    setValue(value)
  }

  return {
    value,
    setValue: onChange,
  }
}

export type UseOrderByProps = ReturnType<typeof useOrderBy>
