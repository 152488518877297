import { useMemo } from 'react'
import groupBy from 'lodash/groupBy'
import { useTranslation } from 'react-i18next'
import {
  ClusterMetric,
  ClusterResourceMetrics,
  ListClusterMetricsResponse,
} from '@cloudnatix-types/dashboard'

import { AreaGraphProps, LineGraphProps } from 'src/next/components/Graphs'
import {
  createFormatFn,
  getCarbonPaletteCssVariable,
} from 'src/next/utils/graph.utils'

import { useClusterMetricsQuery } from './useClusterMetricsQuery'

const sumKeys = (items: ClusterResourceMetrics[]) =>
  items.reduce(
    (acc, curr) => {
      Object.entries(curr).forEach(([key, value]) => {
        acc[key] = acc[key] ? (acc[key] += value) : (acc[key] = value)
      })
      return acc
    },
    {} as Record<string, number>,
  )

const transformData = (
  data: ListClusterMetricsResponse,
  clusterIds?: string[],
) => {
  if (!data.clusterMetrics) return []

  const metrics = clusterIds?.length
    ? Object.entries(data.clusterMetrics)
        .filter(([key]) => clusterIds.includes(key))
        .map(([, value]) => value.metrics)
    : data.clusterTotalMetrics

  // Flatten arrays
  const flattenMetrics = metrics?.flat()

  // Group by timestamp
  const groupByTimeStamp = groupBy(flattenMetrics, 'summaryTimeNs') as Record<
    number,
    ClusterMetric[]
  >

  // Sum keys (avgUsage, requested, ...) within 'cpu' and 'memory' values for each timestamp
  const summedMetricsPerTimeStamp = Object.values(groupByTimeStamp).map(
    group => ({
      summaryTimeMs: Number(group[0]?.summaryTimeNs) / 1_000_000,
      cpu: sumKeys(group.map(item => item.cpu) as ClusterResourceMetrics[]),
      memory: sumKeys(
        group.map(item => item.memory) as ClusterResourceMetrics[],
      ),
      gpu: sumKeys(group.map(item => item.gpu) as ClusterResourceMetrics[]),
      gpuMemory: sumKeys(
        group.map(item => item.gpuMemory) as ClusterResourceMetrics[],
      ),
    }),
  )

  return summedMetricsPerTimeStamp
}

export interface UseClusterMetricsGraphProps {
  startTimeNs: string
  endTimeNs: string
  metricToPlot: 'cpu' | 'memory' | 'gpu' | 'gpuMemory'
  clusterIds?: string[]
}

export const useSummaryMetrics = ({
  startTimeNs,
  endTimeNs,
  metricToPlot,
  clusterIds,
}: UseClusterMetricsGraphProps) => {
  const { t } = useTranslation()

  const { isLoading, data, error } = useClusterMetricsQuery(
    {
      filter: {
        startTimeNs,
        endTimeNs,
      },
    },
    {
      select: data => transformData(data, clusterIds),
    },
  )

  // Create graph configs
  const graphConfig = useMemo(() => {
    if (!data) return []

    const sortedData = [...data].sort(
      (a, b) => a.summaryTimeMs - b.summaryTimeMs,
    )

    const tooltipValueTransformFn = (value: any) =>
      createFormatFn(metricToPlot)(value)

    interface Metric {
      id: string
      label: string
      type: 'line' | 'area'
      colorIndex: number
      skipLast?: boolean
    }
    // Available metrics in order of appearance in the legend, graph and tooltip
    const metrics: Metric[] = []
    if (metricToPlot === 'cpu' || metricToPlot === 'memory') {
      metrics.push(
        {
          id: 'requested',
          label: t('Resources.Requested'),
          type: 'line',
          colorIndex: 6,
          // On the last item of metrics returned by the ListClusterMetrics() gRPC
          // method, the Requested field is 0. Don't draw it.
          skipLast: true,
        },
        {
          id: 'avgUsage',
          label: t('Resources.AvgUsage'),
          type: 'area',
          colorIndex: 0,
        },
        {
          id: 'maxUsage',
          label: t('Resources.MaxUsage'),
          type: 'area',
          colorIndex: 1,
        },
      )
    }
    metrics.push({
      id: 'capacity',
      label: t('Resources.Capacity'),
      type: 'line',
      colorIndex: 3,
    })

    return metrics.map(({ id, label, type, colorIndex, skipLast }, i) => {
      const color = getCarbonPaletteCssVariable(colorIndex ?? i)

      const adjustedData =
        skipLast && sortedData.length >= 1
          ? sortedData.slice(0, -1)
          : sortedData
      const points = adjustedData?.map(item => ({
        x: new Date(item!.summaryTimeMs),
        y: item?.[metricToPlot]?.[id],
      }))

      switch (type) {
        case 'line':
          return {
            id,
            label,
            type,
            tooltipLabel: label,
            props: {
              style: {
                data: {
                  stroke: color,
                },
              },
            },
            data: points,
            tooltipValueTransformFn,
          } as LineGraphProps
        case 'area':
          return {
            id,
            label,
            type,
            tooltipLabel: label,
            props: {
              style: {
                data: {
                  fill: color,
                },
              },
            },
            data: points,
            tooltipValueTransformFn,
          } as AreaGraphProps
        default:
          throw new Error(`Unexpected graph type ${type}`)
      }
    })
  }, [data, t, metricToPlot])

  return { isLoading, graphConfig, error }
}
