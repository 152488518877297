import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  SkeletonText,
  Toggletip,
  ToggletipButton,
  ToggletipContent,
} from '@carbon/react'
import { Information } from '@carbon/react/icons'
import styled from 'styled-components'

import { useComputeInstanceOptimizations } from 'src/api/queries'
import { Box, Flex, Grid, Typography } from 'src/next/components'
import {
  DataTablePagination,
  usePagination,
} from 'src/next/components/DataTable'
import { TableClusterFilter } from 'src/next/components/Filters/TableClusterFilter'
import { InlineNotification } from 'src/next/components/InlineNotification'
import { Sidebar, SidebarToggle } from 'src/next/components/Sidebar'
import {
  TableFilter,
  TableFilterAccordionItem,
  TableFilterActiveItems,
  TableFilterSearchSelection,
  TableFilterSlider,
  useTableFilter,
} from 'src/next/components/TableFilter'
import { currencyFormat } from 'src/next/utils'

import { StyledSkeletonPlaceholder } from './Insights'
import { ComputeInstanceInsightsCard } from './InsightsCard'
import { InsightsToolbar } from './InsightsToolbar'

const StyledToggletip = styled(Toggletip)`
  margin-left: var(--cds-spacing-03);
  vertical-align: middle;
`

export interface OrderByItem {
  id: string
  label: string
}

export const ComputeInstanceInsights = () => {
  const { t } = useTranslation()

  const { isOpen, toggleFilters, activeFilters } = useTableFilter()

  const orderByItems: OrderByItem[] = [
    {
      id: 'cost_saving',
      label: t('Insights.OrderBy.CostSavings'),
    },
    {
      id: 'workload_name',
      label: t('Insights.OrderBy.WorkloadName'),
    },
    {
      id: 'spend',
      label: t('Insights.OrderBy.Spend'),
    },
  ]

  const [orderBy, setOrderBy] = useState(orderByItems[0])
  const [isSortDesc, setIsSortDesc] = useState(true)

  const pagination = usePagination('insights')

  useEffect(() => {
    if (pagination.page > 1) pagination.resetPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, isSortDesc, activeFilters])

  const optimizationFilters = { ...activeFilters }

  const query = useComputeInstanceOptimizations(
    {
      filter: optimizationFilters,
      orderBy: `${orderBy.id} ${isSortDesc ? 'desc' : 'asc'}`,
      pageSize: pagination.pageSize,
    },
    {
      staleTime: 60 * 60 * 1000, // 1 hour
    },
  )

  const { data: queryData, isFetching, isError } = query

  const data = queryData?.pages?.[pagination.page - 1] || {}

  return (
    <Box mt={4}>
      {!isFetching && (isError || data === undefined) ? null : (
        <Box minHeight={30}>
          {isFetching ? (
            <SkeletonText style={{ width: 200, height: 24 }} />
          ) : data.totalSavings === undefined ? (
            <InlineNotification
              kind="error"
              title={t('Insights.SavingsTitleError')}
            />
          ) : data.totalSavings > 0 ? (
            <>
              <Typography variant="heading-03" as="h2">
                {data.totalSavingsExcludingDismissed ===
                data.conservativeTotalSavingsExcludingDismissed ? (
                  t('Insights.SavingsTitle', {
                    savings: currencyFormat(
                      data.totalSavingsExcludingDismissed!,
                    ),
                  })
                ) : (
                  <>
                    {t('Insights.SavingsTitleRanged', {
                      low: currencyFormat(
                        data.conservativeTotalSavingsExcludingDismissed!,
                      ),
                      high: currencyFormat(
                        data.totalSavingsExcludingDismissed!,
                      ),
                    })}
                    <StyledToggletip>
                      <ToggletipButton label="information">
                        <Information />
                      </ToggletipButton>
                      <ToggletipContent>
                        <div>{t('Insights.SavingsTitleRangedTooltip')}</div>
                      </ToggletipContent>
                    </StyledToggletip>
                  </>
                )}
              </Typography>
              {data.totalSavingsExcludingDismissed === data.totalSavings &&
              data.conservativeTotalSavingsExcludingDismissed ===
                data.conservativeTotalSavings ? null : (
                <Typography variant="body-short">
                  {data.totalSavings === data.conservativeTotalSavings
                    ? t('Insights.SavingsSubtitle', {
                        savings: currencyFormat(data.totalSavings),
                      })
                    : t('Insights.SavingsSubtitleRanged', {
                        low: currencyFormat(data.conservativeTotalSavings),
                        high: currencyFormat(data.totalSavings),
                      })}
                </Typography>
              )}
            </>
          ) : null}
        </Box>
      )}
      <Sidebar>
        <Sidebar.Panel $open={isOpen} $width={300}>
          <TableFilter>
            <TableFilterActiveItems />

            <TableFilterAccordionItem title={t('Insights.Filters.Name')}>
              <TableFilterSearchSelection
                id="name"
                placeholder={t(
                  'Insights.Filters.ComputeInstanceNamePlaceHolder',
                )}
              />
            </TableFilterAccordionItem>

            <TableFilterAccordionItem
              title={t('Insights.Filters.AutoScalingGroupName')}
            >
              <TableFilterSearchSelection
                id="autoScalingGroupNames"
                placeholder={t(
                  'Insights.Filters.AutoScalingGroupNamePlaceHolder',
                )}
              />
            </TableFilterAccordionItem>

            <TableFilterAccordionItem
              title={t('Insights.Filters.MinimumCostSaving')}
            >
              <TableFilterSlider
                id="minCostSaving"
                min={0}
                max={
                  // @ts-ignore Latest types are missing TODO remove this ignore
                  Math.floor(data?.maxCostSaving || 1000)
                }
              />
            </TableFilterAccordionItem>

            <TableClusterFilter includeVMs={true} />
          </TableFilter>
        </Sidebar.Panel>
        <Sidebar.MainContent>
          <Flex justifyContent="flex-end">
            <InsightsToolbar
              setOrderBy={setOrderBy}
              setIsSortDesc={setIsSortDesc}
              orderByItems={orderByItems}
              isSortDesc={isSortDesc}
              orderBy={orderBy}
            />
            <SidebarToggle open={isOpen} onToggle={toggleFilters} />
          </Flex>
          {isFetching ? null : isError || data?.plans?.length === undefined ? (
            <InlineNotification
              kind="error"
              title={t('Insights.LoadingError', 'Failed to load insights')}
            />
          ) : data.plans.length === 0 ? (
            <InlineNotification title={t('Insights.NoData')} />
          ) : null}
          <Grid
            gap={5}
            gridTemplateColumns={{ _: '1fr', lg: '1fr 1fr' }}
            marginTop={6}
          >
            {isFetching ? (
              [...Array(4)].map((_, i) => <StyledSkeletonPlaceholder key={i} />)
            ) : (
              <>
                {data?.plans?.map(plan => (
                  <Box key={plan.uuid}>
                    <ComputeInstanceInsightsCard plan={plan} />
                  </Box>
                ))}
              </>
            )}
          </Grid>
          {data.plans ? (
            <DataTablePagination
              pageSize={pagination.pageSize}
              page={pagination.page}
              onChange={pagination.onChange}
              query={query}
            />
          ) : null}
        </Sidebar.MainContent>
      </Sidebar>
    </Box>
  )
}
