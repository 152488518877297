import { Button } from '@carbon/react'
import { Information } from '@carbon/react/icons'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ComputeInstanceSummary } from '@cloudnatix-types/dashboard'

import { useCurrentOrgInstanceSummaries } from 'src/api'
import {
  useRollupPeriod,
  useTimeRangeOverflowMenu,
} from 'src/next/components/dashboard/timerange'
import { Graph } from 'src/next/components/Graphs/Graph'
import GraphTooltip, {
  useGraphTooltipFloating,
} from 'src/next/components/Graphs/GraphTooltip'
import GraphTooltipContainer from 'src/next/components/Graphs/GraphTooltipContainer'
import { LineGraphProps } from 'src/next/components/Graphs/graphTypes'
import Loading from 'src/next/components/Loading'
import Tooltip from 'src/next/components/Tooltip'
import { buildDayTicks } from 'src/next/utils/graph.utils'
import { nanoToMilliSeconds } from 'src/next/utils/unitConversion'

import { MetricContainer } from '../../dashboard/metrics/MetricContainer'
import { useTooltipHeading } from '../../dashboard/metrics/useTooltipHeading'

export const NumAccountsMetrics = () => {
  const { t } = useTranslation()

  const id = 'dashboard-metrics-num-accounts'

  const {
    selectedItem: selectedTimeRange,
    startTimeNs,
    endTimeNs,
  } = useTimeRangeOverflowMenu({
    id: `${id}-time-range`,
  })

  const { selectedItem: duration } = useRollupPeriod({
    id: `${id}-duration`,
    timeRange: selectedTimeRange.id,
  })

  const { data, isLoading } = useCurrentOrgInstanceSummaries({
    duration: duration.id,
    startTimeNs,
    endTimeNs,
  })

  const dataPoints = useMemo(
    () =>
      data?.summaries?.map((summary: ComputeInstanceSummary) => ({
        x: new Date(nanoToMilliSeconds(summary.timestampNs!)),
        y: summary.numAccounts!,
      })) || [],
    [data],
  )

  const xTickValues = useMemo(() => buildDayTicks(dataPoints), [dataPoints])

  const graphConfig = [
    {
      id: 'num_accounts',
      label: t('ComputeInstanceSummary.num_accounts'),
      tooltipLabel: t('ComputeInstanceSummary.num_accounts'),
      tooltipValueTransformFn: (value: unknown) => `${value}`,
      enabled: true,

      type: 'line' as const,
      data: dataPoints,
      props: {
        style: { data: { stroke: 'var(--carbonPalette4)' } },
      },
    } as LineGraphProps,
  ]

  const getTooltipHeading = useTooltipHeading(duration.id)

  const { reference, ...graphTooltipProps } = useGraphTooltipFloating()

  return (
    <MetricContainer
      id={id}
      title={
        <>
          {t('ComputeInstanceSummary.num_accounts')}
          <Tooltip
            label={t('Dashboard.Metrics.NumAccountsTooltip')}
            align="bottom"
          >
            <Button
              size="sm"
              kind="ghost"
              hasIconOnly
              tooltipPosition="bottom"
              renderIcon={Information}
            />
          </Tooltip>
        </>
      }
    >
      {isLoading ? (
        <Loading centered size="small" withOverlay={false} />
      ) : (
        <>
          {reference}
          <Graph
            data={graphConfig}
            unit="none"
            height={200}
            xAxis={[
              {
                tickFormat: (x: any) => dayjs(x).format('MM/DD'),
                tickValues: xTickValues,
              },
            ]}
            yAxis={[{}]}
            containerComponent={GraphTooltipContainer(
              <GraphTooltip
                graphConfig={graphConfig}
                heading={getTooltipHeading}
                {...graphTooltipProps}
              />,
            )}
          />
        </>
      )}
    </MetricContainer>
  )
}
