import React, { useState } from 'react'
import { Dropdown, MultiSelect } from '@carbon/react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Cluster } from '@cloudnatix-types/dashboard'

import { useClusters } from 'src/api'
import { Box } from 'src/next/components/Box'
import { useTimeRangeOverflowMenu } from 'src/next/components/dashboard/timerange'
import { useLegend } from 'src/next/components/Graphs'
import { defaultPadding, Graph } from 'src/next/components/Graphs/Graph'
import GraphTooltip, {
  useGraphTooltipFloating,
} from 'src/next/components/Graphs/GraphTooltip'
import GraphTooltipContainer from 'src/next/components/Graphs/GraphTooltipContainer'
import Loading from 'src/next/components/Loading'
import { Toolbar } from 'src/next/components/Toolbar'
import { usePersistentMetricDropdown } from 'src/next/hooks/usePersistentMetricDropdown'

import { useSummaryMetrics } from './useSummaryMetrics'

export const getTooltipHeading = (activePoint: any) => {
  const date = dayjs(activePoint?.x)
  return date.isValid() ? date.format('LL') : ''
}

export const ClustersSummaryChart = () => {
  const { t } = useTranslation()

  const { data: clustersResp } = useClusters()
  const clusters = clustersResp?.clusters || []

  const [selectedClusters, setSelectedClusters] = useState<
    Cluster[] | undefined
  >(undefined)

  const { selectedItem: metricToPlot, dropdownProps: metricDropdownProps } =
    usePersistentMetricDropdown('clusters-overview-summary-metric')

  const { TimeRangeMenu, items, selectedItem, startTimeNs, endTimeNs } =
    useTimeRangeOverflowMenu({
      id: 'clusters-overview-chart-time-range',
      show24HoursOption: false,
    })

  const { isLoading, graphConfig } = useSummaryMetrics({
    startTimeNs,
    endTimeNs,
    clusterIds: selectedClusters?.map(c => c.uuid!),
    metricToPlot,
  })

  const {
    Legend,
    graphConfig: legendGraphConfig,
    legendProps,
  } = useLegend(graphConfig || [])

  const hasData = legendGraphConfig.some(
    graph => graph.data && graph.data.length > 0 && graph.enabled,
  )

  const { reference, ...graphTooltipProps } = useGraphTooltipFloating()

  return (
    <Box position="relative">
      <Toolbar>
        <Toolbar.Group pullRight>
          <Dropdown label={t('Common.Metric')} {...metricDropdownProps} />
          <Box minWidth={200} ml={4}>
            <MultiSelect
              id="clusters-overview-summary-clusters"
              label={t('Clusters.SelectClusters')}
              hideLabel
              items={clusters}
              itemToString={item => item.name || ''}
              onChange={({ selectedItems }) => {
                setSelectedClusters(selectedItems)
              }}
            />
          </Box>
          <TimeRangeMenu label={selectedItem.label} items={items} />
        </Toolbar.Group>
      </Toolbar>

      <Box width="100%" height={350} mt={20}>
        {isLoading ? (
          <Loading
            small
            centered
            withOverlay={false}
            data-testid="loading-clusters-graphs"
          />
        ) : !hasData ? (
          <p>No data found (for selected time range)</p>
        ) : (
          <>
            {reference}
            <Graph
              data={legendGraphConfig.reverse()}
              unit={metricToPlot}
              xAxis={[
                {
                  tickFormat: (x: any) => dayjs(x).format('MM/DD'),
                },
              ]}
              yAxis={[{}]}
              containerComponent={GraphTooltipContainer(
                <GraphTooltip
                  graphConfig={graphConfig}
                  heading={getTooltipHeading}
                  {...graphTooltipProps}
                />,
              )}
            />
          </>
        )}
      </Box>

      <Box
        width="100%"
        padding={4}
        paddingTop={2}
        paddingLeft={`${defaultPadding.left}px`}
      >
        {!isLoading && hasData ? <Legend {...legendProps} /> : null}
      </Box>
    </Box>
  )
}
